<template>
  <div
    class="row csn-acc-verification csn-user-account-section"
    id="user-account"
  >
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1 class="csn-acc-verification-title">{{ t('title') }}</h1>
          <p>{{ t('description_1') }}</p>
          <p>{{ t('description_2') }}</p>
          <p>{{ t('description_3') }}</p>
          <p>{{ t('description_4') }}</p>
        </section>
        <section>
          <h2 class="csn-acc-verification-subtitle">{{ t('subtitle') }}</h2>
          <ol>
            <li>
              <span class="csn-acc-verification-step-title">
                {{ t('list_1_title') }}
              </span>
              <ul>
                <li>{{ t('list_1_item_1') }}</li>
                <li>{{ t('list_1_item_2') }}</li>
              </ul>
            </li>
            <li>
              <span class="csn-acc-verification-step-title">
                {{ t('list_2_title') }}
              </span>
              <ul>
                <span class="csn-acc-verification-step-subtitle">
                  {{ t('list_2_subtitle') }}
                </span>
                <li>{{ t('list_2_item_1') }}</li>
                <li>{{ t('list_2_item_2') }}</li>
                <li>{{ t('list_2_item_3') }}</li>
              </ul>
            </li>
            <li>
              <span class="csn-acc-verification-step-title">
                {{ t('list_3_title') }}
              </span>
              <ul>
                <li>{{ t('list_3_item_1') }}</li>
                <li>{{ t('list_3_item_2') }}</li>
                <li>{{ t('list_3_item_3') }}</li>
              </ul>
            </li>
          </ol>
          <Route
            class="btn casino-btn-ver-1 csn-acc-verification-upload-btn casino-btn casino-btn-theme-based"
            :to="{ name: userDocumentsPath }"
          >
            {{ t('upload_doctuments_bttn_label') }}
          </Route>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ACCOUNT_VERIFICATION, RouteName } from '@/constants'

export default {
  name: ACCOUNT_VERIFICATION,
  components: {
    Route: () => import('@/components/Route'),
  },
  computed: {
    t() {
      return this.$createComponentTranslator(ACCOUNT_VERIFICATION)
    },
    userDocumentsPath() {
      return RouteName.USER_DOCUMENTS
    },
  },
}
</script>
